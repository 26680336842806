var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{attrs:{"align":"end","justify":"space-between","no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('GoBack'),_c('p',{staticClass:"header-text"},[_vm._v(" "+_vm._s(_vm.batchData.name)+" ")]),_c('p',{staticClass:"lightCarbon--text font-weight-regular caption"},[_vm._v(" "+_vm._s(_vm.batchData.number_of_users)+" students ")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"px-14 button-start-checking",attrs:{"color":"primary","depressed":"","min-height":"55","min-width":"60","to":{
          name: 'StartChecking',
          query: {
            batchId: _vm.batchId,
          },
        }}},[_vm._v(" Start Checking ")]),_c('p',{staticClass:"mt-1 font-weight-medium text--secondary caption"},[_vm._v(" Start checking assignments for this batch ")])],1)],1),_c('v-divider',{staticClass:"mt-2 mb-4"}),_c('v-row',{staticClass:"my-6 mb-16",attrs:{"dense":""}},_vm._l((_vm.cards),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"12","lg":"3","md":"6"}},[_c('AnalyticsCard',{attrs:{"body":item.body,"color":item.color,"title":item.title}})],1)}),1),_c('div',{staticClass:"my-6"},[_c('ViewBatchSearch',{attrs:{"get-batches-data":_vm.getBatchesData,"reset":_vm.resetPageNo}})],1),_c('v-data-table',{attrs:{"custom-sort":() => _vm.viewBatches.data.data,"headers":_vm.headers,"hide-default-footer":"","items":_vm.viewBatches.data.data,"loading":_vm.listLoading,"multi-sort":"","sort-by":_vm.viewBatches.sort.fields,"sort-desc":_vm.viewBatches.sort.desc},on:{"update:options":_vm.handleSort},scopedSlots:_vm._u([{key:`header.action`,fn:function(){return [_c('span',{staticClass:"subtitle-2 lightCarbon--text"},[_vm._v(" "+_vm._s(_vm.totalEntries)+" ")])]},proxy:true},{key:`item.user`,fn:function({ item }){return [_c('UserNameAndAvatar',{attrs:{"full-name":item.full_name,"url":item.photo}})]}},{key:`item.total_submissions`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.total_submissions)+" of "+_vm._s(item.allocated_assignments)+" "),(item.allocated_assignments > _vm.batchData.total_assignment)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}],null,true)},[_c('span',[_vm._v("This student has specially allocated assignments")])]):_vm._e()]}},{key:`item.action`,fn:function({ item }){return [_c('v-btn',{attrs:{"color":"primary","small":"","text":"","to":{
          name: 'ViewStudent',
          params: { studentId: item.uuid },
        }}},[_vm._v(" VIEW STUDENT ")])]}},{key:"footer",fn:function(){return [_c('Paginator',{attrs:{"page":_vm.viewBatches.page,"total":_vm.viewBatches.data.total},on:{"change":_vm.handlePagination}})]},proxy:true}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }