<template>
  <div>
    <v-row align="end" justify="space-between" no-gutters>
      <v-col cols="auto">
        <GoBack />
        <p class="header-text">
          {{ batchData.name }}
        </p>
        <p class="lightCarbon--text font-weight-regular caption">
          {{ batchData.number_of_users }} students
        </p>
      </v-col>
      <v-col cols="auto">
        <v-btn
          class="px-14 button-start-checking"
          color="primary"
          depressed
          min-height="55"
          min-width="60"
          :to="{
            name: 'StartChecking',
            query: {
              batchId,
            },
          }"
        >
          Start Checking
        </v-btn>
        <p class="mt-1 font-weight-medium text--secondary caption">
          Start checking assignments for this batch
        </p>
      </v-col>
    </v-row>

    <v-divider class="mt-2 mb-4" />

    <v-row class="my-6 mb-16" dense>
      <v-col
        v-for="(item, index) in cards"
        :key="index"
        cols="12"
        lg="3"
        md="6"
      >
        <AnalyticsCard
          :body="item.body"
          :color="item.color"
          :title="item.title"
        />
      </v-col>
    </v-row>

    <div class="my-6">
      <ViewBatchSearch
        :get-batches-data="getBatchesData"
        :reset="resetPageNo"
      />
    </div>

    <v-data-table
      :custom-sort="() => viewBatches.data.data"
      :headers="headers"
      hide-default-footer
      :items="viewBatches.data.data"
      :loading="listLoading"
      multi-sort
      :sort-by="viewBatches.sort.fields"
      :sort-desc="viewBatches.sort.desc"
      @update:options="handleSort"
    >
      <template v-slot:[`header.action`]>
        <span class="subtitle-2 lightCarbon--text">
          {{ totalEntries }}
        </span>
      </template>

      <template v-slot:[`item.user`]="{ item }">
        <UserNameAndAvatar :full-name="item.full_name" :url="item.photo" />
      </template>

      <template v-slot:[`item.total_submissions`]="{ item }">
        {{ item.total_submissions }} of {{ item.allocated_assignments }}
        <v-tooltip
          v-if="item.allocated_assignments > batchData.total_assignment"
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" small v-on="on">
              mdi-information
            </v-icon>
          </template>
          <span>This student has specially allocated assignments</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-btn
          color="primary"
          small
          text
          :to="{
            name: 'ViewStudent',
            params: { studentId: item.uuid },
          }"
        >
          VIEW STUDENT
        </v-btn>
      </template>

      <template v-slot:footer>
        <Paginator
          :page="viewBatches.page"
          :total="viewBatches.data.total"
          @change="handlePagination"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ViewBatchSearch from "@/components/dashboard/assignments/ViewBatchSearch.vue"
import Paginator from "@/components/shared/Paginator.vue"
import AnalyticsCard from "@/components/shared/AnalyticsCard.vue"
import GoBack from "@/components/shared/GoBack.vue"
import UserNameAndAvatar from "@/components/shared/UserNameAndAvatar.vue"
import urls from "@/utils/urls"
import { getValueOrNull } from "@/utils/helpers"
import { mapGetters, mapActions } from "vuex"

export default {
  name: "ViewBatch",

  components: {
    ViewBatchSearch,
    Paginator,
    AnalyticsCard,
    GoBack,
    UserNameAndAvatar,
  },

  data: () => ({
    batchId: null,
    headers: [
      {
        text: "Name",
        value: "user",
        sortable: false,
      },
      { text: "Email Address", value: "email", sortable: false },
      { text: "Pending Reviews", value: "pending_reviews", sortable: false },
      {
        text: "Total Submissions",
        value: "total_submissions",
        sortable: false,
      },
      {
        text: "",
        value: "action",
        sortable: false,
        align: "end",
      },
    ],
    batchData: {
      accepted_submissions: 0,
      total_assignment: 0,
      pending_reviews: 0,
      total_submissions: 0,
      number_of_users: 0,
      name: "",
    },
  }),

  computed: {
    ...mapGetters(["viewBatches", "listLoading"]),

    totalEntries() {
      const { data, page } = this.viewBatches

      return `${page * 10 > data.total ? data.total : page * 10} of ${
        data.total
      } Entries`
    },

    cards() {
      const {
        total_assignment,
        total_submissions,
        accepted_submissions,
        pending_reviews,
      } = this.batchData
      return [
        {
          title: "Total Assignments",
          color: "purple",
          body: total_assignment,
        },
        {
          title: "Total Submissions",
          color: "yellow",
          body: total_submissions,
        },
        {
          title: "Accepted Submissions",
          color: "green",
          body: accepted_submissions,
        },
        {
          title: "Pending Reviews",
          color: "red",
          body: pending_reviews,
        },
      ]
    },
  },

  beforeMount: function() {
    this.batchId = this.$route.params.batchId
  },

  mounted: function() {
    this.getBatchesData()
  },

  methods: {
    ...mapActions(["getViewBatches"]),

    // Analatics
    async getBatchesData() {
      try {
        const res = await this.$http.get(urls.batches.viewBatch(this.batchId), {
          params: {
            course: getValueOrNull(this.viewBatches.selectedCourse),
            status: getValueOrNull(this.viewBatches.selectedStatus),
          },
        })
        this.batchData = res.data.data
      } catch (error) {
        console.error(error)
      }
    },

    resetPageNo() {
      this.$store.commit("SET_VIEW_BATCH_PAGE", { page: 1 })
    },

    handleSort({ sortBy, sortDesc }) {
      this.resetPageNo()
      this.$store.commit("SET_VIEW_BATCH_SORTABLE_FIELDS", {
        fields: sortBy,
        desc: sortDesc,
      })
      this.getViewBatches(this.batchId)
    },

    handlePagination(newPage) {
      this.$store.commit("SET_VIEW_BATCH_PAGE", { page: newPage })
      this.getViewBatches(this.batchId)
    },
  },
}
</script>

<style lang="scss" scoped>
.email {
  word-break: break-word;
}
</style>
